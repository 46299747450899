.checkBox {
    @apply relative flex items-baseline pl-0 text-sm text-common-white;
}
.checkBox input {
    @apply absolute left-0 top-0 h-full w-full appearance-none rounded-sm border-interface-600 opacity-0 focus:border-interface-600 focus:shadow-none focus:ring-0;
}
.checkBox .checkmark {
    @apply relative top-0.5 mr-2 h-[16px] w-[16px] flex-shrink-0 rounded border border-interface-300;
}
.checkBox .checkmark i {
    @apply absolute  left-[2px] top-[3px] hidden text-[9px] text-common-white;
}
.checkStyle2 input[type='checkbox'],
.checkStyle2 .checkmark {
    @apply hidden;
}
.checkBox input:checked + .checkmark {
    @apply border-primary-500 bg-primary-500;
}
.checkBox input:checked + .checkmark i {
    @apply block;
}
/* style 2 */
.checkStyle2 label {
    @apply py-0 text-sm font-medium text-interface-600 hover:rounded-none hover:bg-transparent;
}
.checkStyle2 {
    @apply relative m-0 flex h-[39px] items-center justify-center overflow-hidden rounded-md border border-interface-300   hover:bg-interface-50;
}
.checkStyle2 input:checked ~ span {
    @apply bg-primary-500 text-common-white;
}
.checkStyle2 .checkBoxLabel {
    @apply w-full cursor-pointer overflow-hidden whitespace-nowrap px-[13px] py-[13px];
}
.checkStyle2.disabled {
    @apply pointer-events-none opacity-50;
}
